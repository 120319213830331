import React from 'react';
import styled from 'styled-components';
import {
  SEO,
  OmnichannelStartYourJourney,
  OmnichannelCarousel,
  OmnichannelHero,
  Versus,
  Underline,
  RenderWhenReady
} from 'components';
import { OmnichannelSummary, MultichannelSummary } from 'images/omnichannel';

const Omnichannel = () => (
  <>
    <SEO
      pageSpecificTitle="Omnichannel Healthcare Campaigns"
      pageSpecificDescription="A practitioner's guide to integrated Healthcare Campaigns."
      pageSpecificThumbnail="/thumbnails/aiCaseStudy/thumbnail.jpg"
      pageSpecificThumbnailAlt="A galaxy"
    />
    <RenderWhenReady>
      <OmnichannelHero />
    </RenderWhenReady>
    <Section style={{ paddingBottom: 0 }}>
      <RenderWhenReady>
        <Versus />
      </RenderWhenReady>
    </Section>
    <Section>
      <Subtitle>First. Let&apos;s define it.</Subtitle>
      <Article>
        <p>
          <strong>
            <Underline color="#F096F9" width={3} />
            Omnichannel
          </strong>{' '}
          - A Patient or HCP centric campaign that serves the right content, at the right time, via
          the right medium - delivering a seamless and connected audience experience via a holistic
          approach. An omnichannel campaign usually follows through different engagement touchpoints
          of a customer journey for example Awareness, Engagement and Advocate.
        </p>
        <OmnichannelSummary />
        <p>
          <strong>
            <Underline color="#65E1FB" width={3} />
            Multichannel
          </strong>{' '}
          - A campaign where the content is chosen based on the medium, and often unconnected. For
          example: Sales/Customer-facing team content vs Web content vs Ad content.
        </p>
        <MultichannelSummary />
      </Article>
    </Section>
    <Section>
      {/* <AXBenefits /> */}
      <Subtitle style={{ marginBottom: 0, maxWidth: '1180px' }}>
        All Omnichannel experiences will use multiple channels, but not all Multichannel experiences
        are Omnichannel.
      </Subtitle>
    </Section>
    <Section>
      <OmnichannelCarousel />
    </Section>
    <Section>
      <OmnichannelStartYourJourney />
    </Section>
  </>
);

const Subtitle = styled.h2`
  grid-column: 2/3;
  margin: 0 auto;
  font-size: 1.5rem;
  color: var(--ax-gold);
  font-weight: 800;
  text-align: center;
  svg {
    height: 1rem;
    width: auto;
    margin-right: 0.5rem;
  }
  @media (min-width: 48rem) {
    font-size: 2rem;
    text-align: center;
    svg {
      height: 1.3rem;
    }
  }
`;

const Article = styled.article`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3.5rem;
  margin: 0 auto;

  p {
    line-height: 1.3em;
    font-size: 1.25rem;

    @media screen and (min-width: 48rem) {
      line-height: 1.6em;
    }

    strong {
      position: relative;

      svg {
        position: absolute;
        bottom: -0.225em;
        width: 100%;
      }
    }
  }

  @media (min-width: 67.5em) {
    grid-gap: 3rem 5rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;

    p:nth-of-type(1) {
      grid-area: 1/1;
    }
    p:nth-of-type(2) {
      grid-area: 1/2;
    }
    svg:nth-of-type(2) {
      align-self: end;
    }
  }
`;

const Section = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0;
  margin: 0 auto;

  > div {
    grid-column: 2/3;
  }
  > h2 {
    color: var(--ax-gold);
    font-size: 1.75rem;
    grid-column: 2/3;
    margin-bottom: 3rem;
    text-align: center;
  }
  > p {
    font-size: 1.25rem;
    line-height: 1.3em;
    text-align: center;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }
  @media (min-width: 48rem) {
    padding: 3rem 0;

    > h2 {
      font-size: 2rem;
    }
    > p {
      margin-bottom: 5rem;
    }
  }
`;

export default Omnichannel;
